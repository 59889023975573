import React from 'react';
import * as XLSX from 'xlsx';

const ExcelExport = ({ data, serviceName }) => {
  const handleExport = () => {
    const formatGridData = (scheduleData, rotationType) => {
      if (!scheduleData) return [];
      
      const rows = [];
      const { vessels, loadPorts, dischargePorts } = scheduleData;
      
      // Create header row with vessel names
      const headerRow = ['Port Type', 'Port'];
      vessels.forEach(vessel => {
        headerRow.push(`${vessel.vesselName || vessel.name} ETA`);
        headerRow.push(`${vessel.vesselName || vessel.name} ETD`);
        headerRow.push(`${vessel.vesselName || vessel.name} ETA Actual`);
        headerRow.push(`${vessel.vesselName || vessel.name} ETD Actual`);
      });
      rows.push(headerRow);

      // Add load ports
      loadPorts.forEach(port => {
        const row = [`Load Port - ${rotationType}`, port.name];
        port.schedules.forEach(schedule => {
          row.push(schedule.eta?.text || 'N/A');
          row.push(schedule.etd?.text || 'N/A');
          row.push(schedule.eta?.isActual ? 'Yes' : 'No');
          row.push(schedule.etd?.isActual ? 'Yes' : 'No');
        });
        rows.push(row);
      });

      // Add discharge ports
      dischargePorts.forEach(port => {
        const row = [`Discharge Port - ${rotationType}`, port.name];
        port.schedules.forEach(schedule => {
          row.push(schedule.eta?.text || 'N/A');
          row.push(schedule.etd?.text || 'N/A');
          row.push(schedule.eta?.isActual ? 'Yes' : 'No');
          row.push(schedule.etd?.isActual ? 'Yes' : 'No');
        });
        rows.push(row);
      });

      return rows;
    };

    try {
      // Create separate sheets for current and upcoming rotations
      const workbook = XLSX.utils.book_new();
      
      if (data.current) {
        const currentSheet = XLSX.utils.aoa_to_sheet(formatGridData(data.current, 'Current'));
        XLSX.utils.book_append_sheet(workbook, currentSheet, 'Current Rotation');
      }
      
      if (data.upcoming) {
        const upcomingSheet = XLSX.utils.aoa_to_sheet(formatGridData(data.upcoming, 'Upcoming'));
        XLSX.utils.book_append_sheet(workbook, upcomingSheet, 'Upcoming Rotation');
      }

      // Generate filename with service name and date
      const date = new Date().toISOString().split('T')[0];
      const filename = `${serviceName.toLowerCase()}-schedule-${date}.xlsx`;

      XLSX.writeFile(workbook, filename);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Failed to export schedule. Please try again.');
    }
  };

  return (
    <button
      onClick={handleExport}
      className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      title="Export to Excel"
    >
      <svg 
        className="w-4 h-4 mr-2" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
      Export Schedule
    </button>
  );
};

export default ExcelExport;