import React from 'react';
import { fetchProteaSchedules } from '../services/ProteaApi';
import ExcelExport from './ExcelExport';
import RefreshButton from './RefreshButton';
import LoadingIndicator from './LoadingIndicator';
import { ScheduleCache } from '../services/ScheduleCache';
import ScheduleGrid from './ScheduleGrid';

const VESSELS_PER_GRID = 8;

const ProteaScheduleView = () => {
  const [scheduleData, setScheduleData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const loadScheduleData = async (clearCache = true) => {
    try {
      setIsLoading(true);
      
      if (!clearCache) {
        const cachedData = ScheduleCache.getCache('PROTEA');
        if (cachedData) {
          setScheduleData(cachedData);
          setError(null);
          setIsLoading(false);
          return;
        }
      } else {
        ScheduleCache.clearCache('PROTEA');
      }
      
      const data = await fetchProteaSchedules();
      
      if (data.current && data.current.vessels) {
        data.current.vessels = data.current.vessels.slice(0, VESSELS_PER_GRID);
        data.current.loadPorts = data.current.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.current.dischargePorts = data.current.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }
      
      if (data.upcoming && data.upcoming.vessels) {
        data.upcoming.vessels = data.upcoming.vessels.slice(0, VESSELS_PER_GRID);
        data.upcoming.loadPorts = data.upcoming.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.upcoming.dischargePorts = data.upcoming.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }
      
      ScheduleCache.setCache('PROTEA', data);
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error('Error loading schedule data:', error);
      setError('Failed to load schedule data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadScheduleData(false);
  }, []);

  if (isLoading) return <LoadingIndicator />;
  if (error) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-red-600 dark:text-red-400">{error}</div>
    </div>
  );
  if (!scheduleData) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600 dark:text-gray-300">No schedule data available</div>
    </div>
  );

  return (
    <div className="p-6 space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Protea Service Schedule</h1>
        <div className="flex space-x-3">
          <RefreshButton onRefresh={() => loadScheduleData(true)} />
          <ExcelExport data={scheduleData} serviceName="PROTEA" />
        </div>
      </div>

      <div className="space-y-4">
        <div className="mb-2">
          <h2 className="text-lg font-semibold text-blue-600 dark:text-blue-400">Primary Schedule</h2>
        </div>
        <ScheduleGrid
          vessels={scheduleData.current.vessels}
          loadPorts={scheduleData.current.loadPorts}
          dischargePorts={scheduleData.current.dischargePorts}
        />
      </div>

      {scheduleData.upcoming.vessels.length > 0 && (
        <div className="space-y-4">
          <div className="mb-2">
            <h2 className="text-lg font-semibold text-green-600 dark:text-green-400">Extended Schedule</h2>
          </div>
          <ScheduleGrid
            vessels={scheduleData.upcoming.vessels}
            loadPorts={scheduleData.upcoming.loadPorts}
            dischargePorts={scheduleData.upcoming.dischargePorts}
          />
        </div>
      )}
    </div>
  );
};

export default ProteaScheduleView;