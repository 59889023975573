import { ScheduleCache } from './ScheduleCache';

const API_KEY = process.env.REACT_APP_MAERSK_API_KEY;
const API_BASE_URL = 'https://api.maersk.com/schedules';
const MESAWA_SERVICE_CODE = 'MW1';
const MESAWA_SERVICE_NAME = 'MESAWA';
const VESSELS_PER_GRID = 8;

// Port configurations
const LOAD_PORTS = [
  { code: 'ZACPT', name: 'Cape Town', transitTime: 0 },
  { code: 'ZAPLZ', name: 'Port Elizabeth', transitTime: 6 }
];

const DISCHARGE_PORTS = [
  { code: 'AEJEA', name: 'Jebel Ali', transitTime: 19 },
  //{ code: 'SADMM', name: 'Dammam', transitTime: 23 },
  //{ code: 'OMSOH', name: 'Sohar', transitTime: 24 },
  { code: 'INMUN', name: 'Mundra', transitTime: 24 },
  { code: 'INNSA', name: 'Jawaharlal Nehru', transitTime: 26 },
  //{ code: 'SAJED', name: 'Jeddah', transitTime: 29 },
  //{ code: 'LKCMB', name: 'Colombo', transitTime: 30 },
  //{ code: 'QADOH', name: 'Doha', transitTime: 38 }
];

// Helper function to add delay between API calls
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle API rate limiting
const rateLimitedFetch = async (url, options, retryCount = 3) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Consumer-Key': API_KEY,
        'Accept': 'application/json',
        ...options?.headers
      }
    });

    if (response.status === 429 && retryCount > 0) {
      console.log('Rate limit hit, waiting before retry...');
      await delay(1000); // Wait 1 second before retry
      return rateLimitedFetch(url, options, retryCount - 1);
    }

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (retryCount > 0) {
      console.log(`Retrying... ${retryCount} attempts left`);
      await delay(1000);
      return rateLimitedFetch(url, options, retryCount - 1);
    }
    throw error;
  }
};

// Request queue implementation
const requestQueue = [];
let isProcessing = false;

const processQueue = async () => {
  if (isProcessing || requestQueue.length === 0) return;
  
  isProcessing = true;
  const { url, options, resolve, reject } = requestQueue.shift();
  
  try {
    const response = await rateLimitedFetch(url, options);
    resolve(response);
  } catch (error) {
    reject(error);
  } finally {
    isProcessing = false;
    setTimeout(() => processQueue(), 1000); // Process next request after 1s
  }
};

const queuedFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    requestQueue.push({ url, options, resolve, reject });
    processQueue();
  });
};

// Helper to get date range
const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 14); // 2 weeks back
  return {
    startDate: startDate.toISOString().split('T')[0],
    dateRange: 'P24W'  // 24 weeks forward
  };
};

// Helper to format dates consistently
const formatDate = (dateTimeStr, isActual = false) => {
  if (!dateTimeStr) return { text: 'N/A', isActual: false };
  
  const date = new Date(dateTimeStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  
  return {
    text: `${day} ${month}`,
    isActual: isActual
  };
};

// Helper function to process port schedules
const processPortSchedules = (portData, vessels) => {
  const schedules = vessels.map(vessel => {
    const portCall = portData.find(call => {
      const matchingVessel = call.facilityCalls?.find(facilityCall => {
        return facilityCall.transport?.vessel?.vesselIMONumber === vessel.vesselIMONumber;
      });
      return matchingVessel !== undefined;
    });

    if (!portCall) {
      return {
        eta: { text: 'N/A', isActual: false },
        etd: { text: 'N/A', isActual: false }
      };
    }

    const facilityCall = portCall.facilityCalls.find(call => 
      call.transport?.vessel?.vesselIMONumber === vessel.vesselIMONumber
    );

    const arrival = facilityCall?.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI');
    const departure = facilityCall?.callSchedules?.find(s => s.transportEventTypeCode === 'DEPA');

    // Check if this is the last discharge port
    const isLastDischargePort = portCall.facility.UNLocationCode === DISCHARGE_PORTS[DISCHARGE_PORTS.length - 1].code;
    
    // If this is the last discharge port and we have an actual arrival
    if (isLastDischargePort && arrival?.eventClassifierCode === 'ACT') {
      const arrivalDate = new Date(arrival.classifierDateTime);
      const oneDayAfter = new Date(arrivalDate);
      oneDayAfter.setDate(oneDayAfter.getDate() + 1);
      
      // If it's been less than a day since actual arrival, keep the vessel
      if (new Date() < oneDayAfter) {
        vessel.keepVisible = true;
      }
    }

    return {
      eta: formatDate(arrival?.classifierDateTime, arrival?.eventClassifierCode === 'ACT'),
      etd: formatDate(departure?.classifierDateTime, departure?.eventClassifierCode === 'ACT')
    };
  });

  return schedules;
};

// Helper function to fetch port schedules
const fetchPortSchedules = async (ports, vessels, dateParams) => {
  return Promise.all(
    ports.map(async port => {
      const url = `${API_BASE_URL}/port-calls?countryCode=${port.code.slice(0, 2)}&cityName=${port.name}&carrierCodes=MAEU&carrierServiceCode=${MESAWA_SERVICE_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`;
      const data = await queuedFetch(url);
      const schedules = processPortSchedules(data?.portCalls || [], vessels);

      return {
        name: port.name,
        schedules: schedules.map((schedule, index) => ({
          vesselName: vessels[index].vesselName,
          voyageNumber: vessels[index].voyageNumber,
          eta: schedule.eta,
          etd: schedule.etd
        }))
      };
    })
  );
};

// Main function to fetch Mesawa schedules
async function fetchMesawaSchedules() {
  try {
    // Check cache first
    const cachedData = ScheduleCache.getCache('MESAWA');
    if (cachedData) {
      console.log('Returning cached MESAWA schedule data');
      return cachedData;
    }

    const vessels = [];
    const processedVessels = new Set();
    const dateParams = getDateRange();
    
    // Fetch Cape Town data first to get vessel list
    const capeTownData = await queuedFetch(
      `${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Cape Town&carrierCodes=MAEU&carrierServiceCode=${MESAWA_SERVICE_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
    );

    if (capeTownData?.portCalls) {
      capeTownData.portCalls.forEach(portCall => {
        portCall.facilityCalls.forEach(facilityCall => {
          const transport = facilityCall.transport;
          if (transport?.outboundService?.carrierServiceCode === MESAWA_SERVICE_CODE || 
              transport?.outboundService?.carrierServiceName === MESAWA_SERVICE_NAME) {
            const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
            if (departure) {
              const vesselKey = `${transport.vessel.vesselName}-${transport.vessel.vesselIMONumber}-${transport.outboundService.carrierVoyageNumber}`;
              if (!processedVessels.has(vesselKey)) {
                processedVessels.add(vesselKey);
                vessels.push({
                  vesselName: transport.vessel.vesselName,
                  vesselIMONumber: transport.vessel.vesselIMONumber,
                  voyageNumber: transport.outboundService.carrierVoyageNumber,
                  departureTime: departure.classifierDateTime
                });
              }
            }
          }
        });
      });
    }

    // Sort vessels by departure time
    const sortedVessels = vessels.sort((a, b) => new Date(a.departureTime) - new Date(b.departureTime));

    // Process port schedules for each vessel
    const currentRotation = sortedVessels.slice(0, VESSELS_PER_GRID);
    const upcomingRotation = sortedVessels.slice(VESSELS_PER_GRID, VESSELS_PER_GRID * 2);

    // Fetch schedules for current rotation
    const [currentLoadPorts, currentDischargePorts] = await Promise.all([
      fetchPortSchedules(LOAD_PORTS, currentRotation, dateParams),
      fetchPortSchedules(DISCHARGE_PORTS, currentRotation, dateParams)
    ]);

    // Fetch schedules for upcoming rotation
    const [nextLoadPorts, nextDischargePorts] = await Promise.all([
      fetchPortSchedules(LOAD_PORTS, upcomingRotation, dateParams),
      fetchPortSchedules(DISCHARGE_PORTS, upcomingRotation, dateParams)
    ]);

    const scheduleData = {
      current: {
        vessels: currentRotation,
        loadPorts: currentLoadPorts,
        dischargePorts: currentDischargePorts
      },
      upcoming: {
        vessels: upcomingRotation,
        loadPorts: nextLoadPorts,
        dischargePorts: nextDischargePorts
      }
    };

    // Cache the data
    ScheduleCache.setCache('MESAWA', scheduleData);

    return scheduleData;
  } catch (error) {
    console.error('Error fetching Mesawa schedules:', error);
    throw error;
  }
}

export { fetchMesawaSchedules };
