import React, { useState, useEffect } from 'react';
import { fetchAmexSchedules } from '../services/AmexApi';
import ExcelExport from './ExcelExport';
import RefreshButton from './RefreshButton';
import { ScheduleCache } from '../services/ScheduleCache';

const VESSELS_PER_GRID = 8;

const ScheduleDateCell = ({ dateInfo }) => (
  <td className={`
    px-2 py-2 text-center border-r dark:border-gray-600 whitespace-nowrap 
    ${dateInfo.isActual 
      ? 'text-green-600 dark:text-green-400 font-medium' 
      : 'text-gray-900 dark:text-gray-100'
    }`}
  >
    {dateInfo.text}
  </td>
);

const ScheduleGrid = ({ vessels, loadPorts, dischargePorts }) => (
  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg w-full">
    <div className="overflow-x-auto">
      <table className="w-full text-sm border-collapse">
        <colgroup>
          <col className="w-[120px]" />
          {vessels.map((_, idx) => (
            <React.Fragment key={idx}>
              <col className="w-[70px]" />
              <col className="w-[70px]" />
            </React.Fragment>
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="px-3 py-2 text-left border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800 font-medium sticky left-0 text-gray-900 dark:text-gray-100">Ports</th>
            {vessels.map((vessel, idx) => (
              <th key={idx} colSpan="2" className="text-center border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                <div className="px-2 py-1 font-medium text-gray-900 dark:text-white">
                  {vessel.vesselName}<br/>{vessel.voyageNumber}
                </div>
                <div className="grid grid-cols-2 border-t dark:border-gray-600">
                  <div className="px-2 py-1 border-r dark:border-gray-600 text-xs font-medium text-gray-600 dark:text-gray-300">ETA</div>
                  <div className="px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300">ETD</div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs">
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-blue-600 dark:text-blue-400 font-medium bg-blue-50 dark:bg-blue-900/20 border-b dark:border-gray-600">
              Load Ports
            </td>
          </tr>
          {loadPorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-green-600 dark:text-green-400 font-medium bg-green-50 dark:bg-green-900/20 border-b dark:border-gray-600">
              Discharge Ports
            </td>
          </tr>
          {dischargePorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const AmexScheduleView = () => {
  const [scheduleData, setScheduleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadSchedules = async (clearCache = true) => {
    try {
      setLoading(true);
      setError(null);
      
      if (clearCache) {
        ScheduleCache.clearCache('AMEX');
      }
      
      const data = await fetchAmexSchedules();
      
      // Limit vessels to VESSELS_PER_GRID for each rotation
      if (data.current && data.current.vessels) {
        data.current.vessels = data.current.vessels.slice(0, VESSELS_PER_GRID);
        data.current.loadPorts = data.current.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.current.dischargePorts = data.current.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }
      
      if (data.upcoming && data.upcoming.vessels) {
        data.upcoming.vessels = data.upcoming.vessels.slice(0, VESSELS_PER_GRID);
        data.upcoming.loadPorts = data.upcoming.loadPorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
        data.upcoming.dischargePorts = data.upcoming.dischargePorts.map(port => ({
          ...port,
          schedules: port.schedules.slice(0, VESSELS_PER_GRID)
        }));
      }
      
      setScheduleData(data);
      setError(null);
    } catch (err) {
      console.error('Error loading AMEX schedules:', err);
      setError('Failed to load schedules. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSchedules(false); // Don't clear cache on initial load
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-white text-lg">Loading schedules...</div>
      </div>
    );
  }
  
  if (error) return <div className="p-6 text-red-600">{error}</div>;
  if (!scheduleData) return <div className="p-6">No schedule data available</div>;

  return (
    <div className="p-6 space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">AMEX Service Schedule</h1>
        <div className="flex space-x-3">
          <RefreshButton onRefresh={() => loadSchedules(true)} />
          <ExcelExport data={scheduleData} serviceName="AMEX" />
        </div>
      </div>

      <div className="space-y-4">
        <div className="mb-2">
          <h2 className="text-lg font-semibold text-blue-600 dark:text-blue-400">Primary Schedule</h2>
        </div>
        <ScheduleGrid
          vessels={scheduleData.current.vessels}
          loadPorts={scheduleData.current.loadPorts}
          dischargePorts={scheduleData.current.dischargePorts}
        />
      </div>

      {scheduleData.upcoming.vessels.length > 0 && (
        <div className="space-y-4">
          <div className="mb-2">
            <h2 className="text-lg font-semibold text-green-600 dark:text-green-400">Extended Schedule</h2>
          </div>
          <ScheduleGrid
            vessels={scheduleData.upcoming.vessels}
            loadPorts={scheduleData.upcoming.loadPorts}
            dischargePorts={scheduleData.upcoming.dischargePorts}
          />
        </div>
      )}
    </div>
  );
};

export default AmexScheduleView;