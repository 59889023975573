import { ScheduleCache } from './ScheduleCache';

const API_KEY = process.env.REACT_APP_MAERSK_API_KEY;
const API_BASE_URL = 'https://api.maersk.com/schedules';
const SERVICE_CODE = '040';
const CARRIER_CODE = 'MAEU';
const CACHE_KEY = 'AMEX';

// Helper function to add delay between API calls
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle API rate limiting
const rateLimitedFetch = async (url, options, retryCount = 3) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Consumer-Key': API_KEY,
        'Accept': 'application/json',
        ...options?.headers
      }
    });

    if (response.status === 429 && retryCount > 0) {
      console.log('Rate limit hit, waiting before retry...');
      await delay(1000); // Wait 1 second before retry
      return rateLimitedFetch(url, options, retryCount - 1);
    }

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (retryCount > 0) {
      console.log(`Retrying... ${retryCount} attempts left`);
      await delay(1000);
      return rateLimitedFetch(url, options, retryCount - 1);
    }
    throw error;
  }
};

// Request queue implementation
const requestQueue = [];
let isProcessing = false;

const processQueue = async () => {
  if (isProcessing || requestQueue.length === 0) return;
  
  isProcessing = true;
  const { url, options, resolve, reject } = requestQueue.shift();
  
  try {
    const response = await rateLimitedFetch(url, options);
    resolve(response);
  } catch (error) {
    reject(error);
  } finally {
    isProcessing = false;
    setTimeout(() => processQueue(), 1000); // Process next request after 1s
  }
};

const queuedFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    requestQueue.push({ url, options, resolve, reject });
    processQueue();
  });
};

// Port configurations matching example data
const LOAD_PORTS = [
  {
    name: 'Durban',
    terminalCode: 'ZADURP1',
    countryCode: 'ZA',
    cityName: 'Durban',
    UNLocationCode: 'ZADUR'
  },
  {
    name: 'Cape Town',
    terminalCode: 'ZACPT04',
    countryCode: 'ZA',
    cityName: 'Cape Town',
    UNLocationCode: 'ZACPT'
  }
];

const DISCHARGE_PORTS = [
  {
    name: 'Newark',
    terminalCode: 'USNWKTM',
    countryCode: 'US',
    cityName: 'Newark',
    UNLocationCode: 'USEWR'
  }
];

const formatDate = (dateTimeStr, isActual) => {
  if (!dateTimeStr || dateTimeStr === 'N/A') return { text: 'N/A', isActual: false };
  const date = new Date(dateTimeStr);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear().toString().slice(2);
  return {
    text: `${day} ${month} ${year}`,
    isActual: isActual
  };
};

const transformScheduleData = (vesselSchedules, config) => {
  const { loadPorts, dischargePorts, serviceCode } = config;
  
  // Constants
  const VESSELS_PER_GRID = 8;

  // Filter for AMEX service vessels based on service code 040 and outbound legs only
  const serviceVessels = vesselSchedules.filter(schedule => 
    schedule.vesselCalls?.some(call =>
      call.transport?.outboundService?.carrierServiceCode === serviceCode
    )
  );

  // Extract and format vessel data with outbound voyage numbers only
  const vessels = serviceVessels.map(schedule => {
    // Find outbound calls for both Durban and Cape Town
    const durbanCall = schedule.vesselCalls?.find(call => 
      call.facility.carrierTerminalCode === 'ZADURP1' &&
      call.transport?.outboundService?.carrierServiceCode === serviceCode
    );
    
    const capeTownCall = schedule.vesselCalls?.find(call => 
      call.facility.carrierTerminalCode === 'ZACPT04' &&
      call.transport?.outboundService?.carrierServiceCode === serviceCode
    );

    // Get the earliest date from either Durban or Cape Town
    const durbanDate = durbanCall?.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI')?.classifierDateTime;
    const capeTownDate = capeTownCall?.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI')?.classifierDateTime;
    
    return {
      vesselName: schedule.vessel.vesselName,
      vesselIMO: schedule.vessel.vesselIMONumber,
      voyageNumber: (durbanCall || capeTownCall)?.transport?.outboundService?.carrierVoyageNumber || '',
      sortDate: durbanDate || capeTownDate || '9999-12-31' // Use far future date if no dates available
    };
  });

  // Sort vessels by their earliest port call date
  vessels.sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate));

  // Remove the sort date before creating the grid
  vessels.forEach(v => delete v.sortDate);

  // Ensure we have enough slots for 8 vessels per grid
  while (vessels.length < VESSELS_PER_GRID * 2) {
    vessels.push({
      vesselName: 'TBN',
      vesselIMO: '',
      voyageNumber: ''
    });
  }

  // Split into current and upcoming rotations (8 vessels each)
  const currentVessels = vessels.slice(0, VESSELS_PER_GRID);
  const upcomingVessels = vessels.slice(VESSELS_PER_GRID, VESSELS_PER_GRID * 2);

  // Format port schedules with consistent date format and outbound legs only
  const formatPortSchedules = (ports, vesselList, schedules) => {
    // Keep track of last load port dates for each vessel
    const lastLoadPortDates = {};

    // First pass - collect all load port dates
    vesselList.forEach(vessel => {
      const schedule = schedules.find(s => s.vessel?.vesselIMONumber === vessel.vesselIMO);
      if (!schedule) return;

      // Find Durban and Cape Town calls
      const durbanCall = schedule.vesselCalls?.find(call => 
        call.facility.carrierTerminalCode === 'ZADURP1' &&
        call.transport?.outboundService?.carrierServiceCode === serviceCode &&
        call.transport?.outboundService?.carrierVoyageNumber === vessel.voyageNumber
      );
      
      const capeTownCall = schedule.vesselCalls?.find(call => 
        call.facility.carrierTerminalCode === 'ZACPT04' &&
        call.transport?.outboundService?.carrierServiceCode === serviceCode &&
        call.transport?.outboundService?.carrierVoyageNumber === vessel.voyageNumber
      );

      // Get departure dates
      const durbanDeparture = durbanCall?.callSchedules?.find(s => s.transportEventTypeCode === 'DEPA')?.classifierDateTime;
      const capeTownDeparture = capeTownCall?.callSchedules?.find(s => s.transportEventTypeCode === 'DEPA')?.classifierDateTime;

      // Use the latest departure date
      if (durbanDeparture && capeTownDeparture) {
        lastLoadPortDates[vessel.vesselIMO] = new Date(durbanDeparture) > new Date(capeTownDeparture) 
          ? durbanDeparture 
          : capeTownDeparture;
      } else {
        lastLoadPortDates[vessel.vesselIMO] = durbanDeparture || capeTownDeparture;
      }
    });
    
    return ports.map(port => ({
      name: port.name,
      schedules: vesselList.map((vessel) => {
        const schedule = schedules.find(s => s.vessel?.vesselIMONumber === vessel.vesselIMO);
        const portCall = schedule?.vesselCalls?.find(call => {
          // Match terminal code
          const matchesTerminal = call.facility.carrierTerminalCode === port.terminalCode;
          
          // For discharge ports (Newark), we need additional validation
          const isDischargePort = port.name === 'Newark';
          if (isDischargePort) {
            // Get arrival date for this call
            const arrivalDate = call.callSchedules?.find(s => s.transportEventTypeCode === 'ARRI')?.classifierDateTime;
            if (!arrivalDate) return false;

            // Check if this arrival is after the last load port date
            const lastLoadDate = lastLoadPortDates[vessel.vesselIMO];
            if (lastLoadDate && new Date(arrivalDate) <= new Date(lastLoadDate)) {
              return false;
            }

            return matchesTerminal && call.transport?.outboundService?.carrierServiceCode === serviceCode;
          }
          
          // For load ports, we check both terminal and voyage number
          const matchesVoyage = call.transport?.outboundService?.carrierVoyageNumber === vessel.voyageNumber;
          const isOutboundService = call.transport?.outboundService?.carrierServiceCode === serviceCode;
          
          return matchesTerminal && matchesVoyage && isOutboundService;
        });

        if (!portCall) return {
          eta: { text: 'N/A', isActual: false },
          etd: { text: 'N/A', isActual: false }
        };

        const arrival = portCall.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
        const departure = portCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');

        return {
          eta: formatDate(arrival?.classifierDateTime, arrival?.eventClassifierCode === 'ACT'),
          etd: formatDate(departure?.classifierDateTime, departure?.eventClassifierCode === 'ACT')
        };
      })
    }));
  };

  return {
    current: {
      vessels: currentVessels,
      loadPorts: formatPortSchedules(loadPorts, currentVessels, serviceVessels),
      dischargePorts: formatPortSchedules(dischargePorts, currentVessels, serviceVessels)
    },
    upcoming: {
      vessels: upcomingVessels,
      loadPorts: formatPortSchedules(loadPorts, upcomingVessels, serviceVessels),
      dischargePorts: formatPortSchedules(dischargePorts, upcomingVessels, serviceVessels)
    }
  };
};

// Helper to get date range
const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 14); // 2 weeks back
  return {
    startDate: startDate.toISOString().split('T')[0],
    dateRange: 'P24W'  // Extended to 24 weeks
  };
};

const getPortCalls = async (port, dateParams) => {
  return queuedFetch(
    `${API_BASE_URL}/port-calls?countryCode=${port.countryCode}&cityName=${port.cityName}&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
  );
};

export const fetchAmexSchedules = async () => {
  // Check cache first
  const cachedData = ScheduleCache.getCache(CACHE_KEY);
  if (cachedData) {
    return cachedData;
  }

  try {
    const dateParams = getDateRange();
    
    // Get vessel calls for load ports
    const loadPortCalls = await Promise.all(
      LOAD_PORTS.map(async port => {
        console.log(`Fetching port calls for ${port.name}`);
        const response = await getPortCalls(port, dateParams);
        console.log(`Port calls for ${port.name}:`, response.portCalls?.length || 0, 'calls found');
        return response;
      })
    );

    // Filter for AMEX service vessels
    const amexVessels = loadPortCalls
      .flatMap(portCall => 
        portCall.portCalls?.flatMap(call => 
          call.facilityCalls?.filter(facilityCall => {
            // Only check outbound service
            const isAmexService = facilityCall.transport?.outboundService?.carrierServiceCode === SERVICE_CODE;
            
            if (isAmexService) {
              console.log('Found AMEX vessel:', {
                vessel: facilityCall.transport?.vessel?.vesselName,
                service: facilityCall.transport?.outboundService?.carrierServiceCode
              });
            }
            
            return isAmexService;
          })
        )
      )
      .filter(Boolean);

    // Extract unique vessel IMO numbers
    const vesselIMOs = [...new Set(amexVessels.map(v => v.transport.vessel.vesselIMONumber))];
    console.log('AMEX vessel IMOs found:', vesselIMOs);

    // Get detailed schedules for each vessel
    const vesselSchedules = (await Promise.all(
      vesselIMOs.map(async imo => {
        try {
          const response = await queuedFetch(
            `${API_BASE_URL}/vessel-schedules?vesselIMONumber=${imo}&carrierCodes=${CARRIER_CODE}&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
          );
          console.log(`Schedule retrieved for vessel ${response.vessel?.vesselName}`);
          return response;
        } catch (error) {
          console.warn(`Error fetching schedule for vessel IMO ${imo}:`, error);
          return null;
        }
      })
    )).filter(schedule => schedule !== null); // Filter out failed schedule fetches

    if (vesselSchedules.length === 0) {
      console.warn('No valid vessel schedules found');
      return {
        current: {
          vessels: [],
          loadPorts: [],
          dischargePorts: []
        },
        upcoming: {
          vessels: [],
          loadPorts: [],
          dischargePorts: []
        }
      };
    }

    // Transform the raw schedule data
    const transformedData = transformScheduleData(vesselSchedules, {
      loadPorts: LOAD_PORTS,
      dischargePorts: DISCHARGE_PORTS,
      serviceCode: SERVICE_CODE
    });

    // Cache the transformed data
    ScheduleCache.setCache(CACHE_KEY, transformedData);

    return transformedData;
  } catch (error) {
    console.error('Error fetching AMEX schedules:', error);
    throw error;
  }
};