import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import SAECSScheduleView from './components/SAECSScheduleView';
import ProteaScheduleView from './components/ProteaScheduleView';
import MesawaScheduleView from './components/MesawaScheduleView';
import AmexScheduleView from './components/AmexScheduleView';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
          <Navigation />
          <main className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<SAECSScheduleView />} />
              <Route path="/amex" element={<AmexScheduleView />} />
              <Route path="/protea" element={<ProteaScheduleView />} />
              <Route path="/mesawa" element={<MesawaScheduleView />} />
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;