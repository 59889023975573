import React from 'react';

const LoadingIndicator = () => {
  return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600 dark:text-gray-300">
        Loading schedules...
      </div>
    </div>
  );
};

export default LoadingIndicator;