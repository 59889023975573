const CACHE_DURATION = process.env.NODE_ENV === 'development' ? 1 * 60 * 1000 : 5 * 60 * 1000; // 1 minute for development, 5 minutes for production

export class ScheduleCache {
  static cache = new Map();

  static getCacheKey(serviceName) {
    return `${serviceName.toLowerCase()}_schedule_cache`;
  }

  static getCacheTimestampKey(serviceName) {
    return `${serviceName.toLowerCase()}_schedule_cache_timestamp`;
  }

  static getCache(serviceName) {
    try {
      const timestamp = localStorage.getItem(this.getCacheTimestampKey(serviceName));
      const now = new Date().getTime();
      
      // Check if cache has expired
      if (!timestamp || now - parseInt(timestamp) > CACHE_DURATION) {
        this.clearCache(serviceName);
        return null;
      }

      const cached = localStorage.getItem(this.getCacheKey(serviceName));
      return cached ? JSON.parse(cached) : null;
    } catch (error) {
      console.warn('Error reading from cache:', error);
      return null;
    }
  }

  static setCache(serviceName, scheduleData) {
    try {
      localStorage.setItem(this.getCacheKey(serviceName), JSON.stringify(scheduleData));
      localStorage.setItem(this.getCacheTimestampKey(serviceName), new Date().getTime().toString());
    } catch (error) {
      console.warn('Error writing to cache:', error);
    }
  }

  static clearCache(serviceName) {
    try {
      localStorage.removeItem(this.getCacheKey(serviceName));
      localStorage.removeItem(this.getCacheTimestampKey(serviceName));
    } catch (error) {
      console.warn('Error clearing cache:', error);
    }
  }

  static isCacheValid(serviceName) {
    try {
      const timestamp = localStorage.getItem(this.getCacheTimestampKey(serviceName));
      if (!timestamp) return false;

      const now = new Date().getTime();
      return (now - parseInt(timestamp)) <= CACHE_DURATION;
    } catch {
      return false;
    }
  }

  static clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.warn('Error clearing cache:', error);
    }
  }
}