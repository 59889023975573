import React, { useState, useEffect } from 'react';
import { RefreshCw } from 'lucide-react';

const REFRESH_COOLDOWN = 300000; // 5 minutes in milliseconds

const RefreshButton = ({ onRefresh }) => {
  const [lastRefresh, setLastRefresh] = useState(() => {
    const stored = localStorage.getItem('lastScheduleRefresh');
    return stored ? parseInt(stored) : 0;
  });
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const timeSinceLastRefresh = now - lastRefresh;
      const remainingTime = Math.max(0, REFRESH_COOLDOWN - timeSinceLastRefresh);
      setTimeLeft(remainingTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [lastRefresh]);

  const handleRefresh = () => {
    const now = Date.now();
    localStorage.setItem('lastScheduleRefresh', now.toString());
    setLastRefresh(now);
    onRefresh();
  };

  const isDisabled = timeLeft > 0;
  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  return (
    <button
      onClick={handleRefresh}
      disabled={isDisabled}
      className={`flex items-center gap-2 px-4 py-2 rounded transition-colors ${
        isDisabled 
          ? 'bg-gray-300 cursor-not-allowed' 
          : 'bg-blue-500 hover:bg-blue-600 text-white'
      }`}
      title={isDisabled ? `Available in ${minutes}m ${seconds}s` : 'Refresh schedule data'}
    >
      <RefreshCw 
        className={`h-5 w-5 ${!isDisabled && 'animate-spin'}`}
      />
      {isDisabled 
        ? `Refresh in ${minutes}m ${seconds}s`
        : 'Refresh Schedule'
      }
    </button>
  );
};

export default RefreshButton;