import React from 'react';

const ScheduleDateCell = ({ dateInfo }) => (
  <td className={`
    px-2 py-2 text-center border-r dark:border-gray-600 whitespace-nowrap 
    ${dateInfo.isActual 
      ? 'text-green-600 dark:text-green-400 font-medium' 
      : 'text-gray-900 dark:text-gray-100'
    }`}
  >
    {dateInfo.text}
  </td>
);

const ScheduleGrid = ({ vessels, loadPorts, dischargePorts, VesselHeaderComponent }) => (
  <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg w-full">
    <div className="overflow-x-auto">
      <table className="w-full text-sm border-collapse">
        <colgroup>
          <col className="w-[120px]" />
          {vessels.map((_, idx) => (
            <React.Fragment key={idx}>
              <col className="w-[70px]" />
              <col className="w-[70px]" />
            </React.Fragment>
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="px-3 py-2 text-left border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800 font-medium sticky left-0 text-gray-900 dark:text-gray-100">Ports</th>
            {vessels.map((vessel, idx) => (
              <th key={idx} colSpan="2" className="text-center border-b border-r dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                <div className="px-2 py-1 font-medium text-gray-900 dark:text-white">
                  {VesselHeaderComponent ? (
                    <VesselHeaderComponent vessel={vessel} index={idx} />
                  ) : (
                    <>
                      {vessel.vesselName || vessel.name}<br/>{vessel.voyageNumber || vessel.voyage}
                    </>
                  )}
                </div>
                <div className="grid grid-cols-2 border-t dark:border-gray-600">
                  <div className="px-2 py-1 border-r dark:border-gray-600 text-xs font-medium text-gray-600 dark:text-gray-300">ETA</div>
                  <div className="px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300">ETD</div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs">
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-blue-600 dark:text-blue-400 font-medium bg-blue-50 dark:bg-blue-900/20 border-b dark:border-gray-600">
              Load Ports
            </td>
          </tr>
          {loadPorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan={vessels.length * 2 + 1} className="px-3 py-2 text-green-600 dark:text-green-400 font-medium bg-green-50 dark:bg-green-900/20 border-b dark:border-gray-600">
              Discharge Ports
            </td>
          </tr>
          {dischargePorts.map((port, portIdx) => (
            <tr key={portIdx} className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700/50">
              <td className="px-3 py-2 border-r dark:border-gray-600 font-medium sticky left-0 bg-white dark:bg-gray-800 text-gray-900 dark:text-white">{port.name}</td>
              {port.schedules.map((schedule, schedIdx) => (
                <React.Fragment key={schedIdx}>
                  <ScheduleDateCell dateInfo={schedule.eta} />
                  <ScheduleDateCell dateInfo={schedule.etd} />
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default ScheduleGrid;