import { ScheduleCache } from './ScheduleCache';

const API_KEY = process.env.REACT_APP_MAERSK_API_KEY;
const API_BASE_URL = 'https://api.maersk.com/schedules';
const PROTEA_SERVICE_CODE = '251';
const PROTEA_SERVICE_NAME = 'PROTEA';
const VESSELS_PER_GRID = 8;

// Port configurations
const LOAD_PORTS = [
  { code: 'ZADUR', name: 'Durban', transitTime: 0 },
  { code: 'ZAPLZ', name: 'Port Elizabeth', transitTime: 6 },
  { code: 'ZACPT', name: 'Cape Town', transitTime: 11 }
];

const DISCHARGE_PORTS = [
  //{ code: 'RERUN', name: 'Port Reunion', transitTime: 11 },
  { code: 'AEJEA', name: 'Jebel Ali', transitTime: 20 },
  { code: 'INMUN', name: 'Mundra', transitTime: 25 },
  { code: 'INNSA', name: 'Jawaharlal Nehru', transitTime: 26 }
];

// Helper function to add delay between API calls
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle API rate limiting
const rateLimitedFetch = async (url, options, retryCount = 3) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Consumer-Key': API_KEY,
        'Accept': 'application/json',
        ...options?.headers
      }
    });

    if (response.status === 429 && retryCount > 0) {
      console.log('Rate limit hit, waiting before retry...');
      await delay(1000); // Wait 1 second before retry
      return rateLimitedFetch(url, options, retryCount - 1);
    }

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    if (retryCount > 0) {
      console.log(`Retrying... ${retryCount} attempts left`);
      await delay(1000);
      return rateLimitedFetch(url, options, retryCount - 1);
    }
    throw error;
  }
};

// Helper to get date range
const getDateRange = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 14); // 2 weeks back
  return {
    startDate: startDate.toISOString().split('T')[0],
    dateRange: 'P24W'  // Using the same format as SAECS
  };
};

// Helper function to separate rotations
const separateRotations = (vesselSchedules) => {
  // Sort all vessels by departure time
  const sortedSchedules = vesselSchedules.sort((a, b) => new Date(a.departureTime) - new Date(b.departureTime));

  return {
    currentRotation: sortedSchedules.slice(0, VESSELS_PER_GRID).map(vs => vs.vessel),
    upcomingRotation: sortedSchedules.slice(VESSELS_PER_GRID, VESSELS_PER_GRID * 2).map(vs => vs.vessel)
  };
};

// Helper function to fetch load schedules
const fetchLoadSchedules = async (vessels, dateParams) => {
  const schedules = [];

  for (const port of LOAD_PORTS) {
    const portSchedules = [];
    
    try {
      console.log(`Fetching load schedule for port: ${port.name}`);
      const response = await rateLimitedFetch(
        `${API_BASE_URL}/port-calls?countryCode=ZA&cityName=${port.name}&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
      );

      if (response?.portCalls) {
        for (const vessel of vessels) {
          const vesselSchedule = processPortSchedules(response.portCalls, vessel);
          portSchedules.push(vesselSchedule);
        }
      }
    } catch (error) {
      console.error(`Error fetching load schedule for ${port.name}:`, error);
      portSchedules.push(...vessels.map(() => ({ eta: { text: 'N/A' }, etd: { text: 'N/A' } })));
    }

    schedules.push({
      name: port.name,
      schedules: portSchedules
    });
  }

  return schedules;
};

// Enhanced function to fetch discharge schedules for multiple vessels
const fetchDischargeSchedules = async (vessels, dateParams) => {
  const schedules = {};
  
  // Process vessels sequentially to respect rate limits
  for (const vessel of vessels) {
    try {
      console.log(`Fetching schedule for vessel: ${vessel.name} (${vessel.vesselIMO})`);
      const response = await rateLimitedFetch(
        `${API_BASE_URL}/vessel-schedules?vesselIMONumber=${vessel.vesselIMO}&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
      );
      
      if (response) {
        schedules[vessel.name] = response;
      }
      
      // Add small delay between requests to help prevent rate limiting
      await delay(200);
    } catch (error) {
      console.error(`Error fetching schedule for vessel ${vessel.name}:`, error);
      schedules[vessel.name] = null;
    }
  }
  
  return schedules;
};

// Main function to fetch Protea schedules
async function fetchProteaSchedules() {
  try {
    // Check cache first
    const cachedData = ScheduleCache.getCache('PROTEA');
    if (cachedData) {
      console.log('Returning cached Protea schedule data');
      return cachedData;
    }

    // Get date range for all API calls
    const dateParams = getDateRange();
    
    // Fetch Durban data first to get vessel list
    const durbanData = await rateLimitedFetch(
      `${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Durban&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`
    );

    const vesselSchedules = [];
    if (durbanData?.portCalls) {
      console.log('Found port calls in Durban, checking for Protea vessels...');
      durbanData.portCalls.forEach(portCall => {
        portCall.facilityCalls.forEach(facilityCall => {
          const transport = facilityCall.transport;
          console.log('Checking vessel:', {
            serviceName: transport?.outboundService?.carrierServiceName,
            serviceCode: transport?.outboundService?.carrierServiceCode,
            vesselName: transport?.vessel?.vesselName
          });
          if (transport?.outboundService?.carrierServiceCode === PROTEA_SERVICE_CODE || 
              transport?.outboundService?.carrierServiceName === PROTEA_SERVICE_NAME) {
            const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
            if (departure) {
              vesselSchedules.push({
                vessel: {
                  name: transport.vessel.vesselName,
                  voyage: transport.outboundService.carrierVoyageNumber,
                  code: transport.vessel.carrierVesselCode,
                  vesselIMO: transport.vessel.vesselIMONumber
                },
                departureTime: new Date(departure.classifierDateTime)
              });
            }
          }
        });
      });
    }

    // Separate the rotations
    const { currentRotation, upcomingRotation } = separateRotations(vesselSchedules);

    // Fetch remaining load port schedules in parallel
    const [peData, capeTownData] = await Promise.all([
      rateLimitedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Port Elizabeth&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`),
      rateLimitedFetch(`${API_BASE_URL}/port-calls?countryCode=ZA&cityName=Cape Town&carrierCodes=MAEU&startDate=${dateParams.startDate}&dateRange=${dateParams.dateRange}`)
    ]);

    // Fetch discharge schedules for all vessels
    console.log(`Fetching discharge schedules for ${[...currentRotation, ...upcomingRotation].length} Protea vessels...`);
    const allVessels = [...currentRotation, ...upcomingRotation];
    const dischargeSchedules = await fetchDischargeSchedules(allVessels, dateParams);

    // Process current rotation
    const currentLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData.portCalls, currentRotation) },
      { name: 'Port Elizabeth', schedules: processPortSchedules(peData.portCalls, currentRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData.portCalls, currentRotation) }
    ];

    const currentDischargePorts = DISCHARGE_PORTS.map(port => ({
      name: port.name,
      schedules: currentRotation.map(vessel => {
        const vesselSchedule = dischargeSchedules[vessel.name];
        return processDischargeSchedule(vesselSchedule, port.name, vessel);
      })
    }));

    // Process upcoming rotation
    const upcomingLoadPorts = [
      { name: 'Durban', schedules: processPortSchedules(durbanData.portCalls, upcomingRotation) },
      { name: 'Port Elizabeth', schedules: processPortSchedules(peData.portCalls, upcomingRotation) },
      { name: 'Cape Town', schedules: processPortSchedules(capeTownData.portCalls, upcomingRotation) }
    ];

    const upcomingDischargePorts = DISCHARGE_PORTS.map(port => ({
      name: port.name,
      schedules: upcomingRotation.map(vessel => {
        const vesselSchedule = dischargeSchedules[vessel.name];
        return processDischargeSchedule(vesselSchedule, port.name, vessel);
      })
    }));

    const result = {
      current: {
        vessels: currentRotation,
        loadPorts: currentLoadPorts,
        dischargePorts: currentDischargePorts
      },
      upcoming: {
        vessels: upcomingRotation,
        loadPorts: upcomingLoadPorts,
        dischargePorts: upcomingDischargePorts
      }
    };

    // Cache the results
    ScheduleCache.setCache('PROTEA', result);
    return result;

  } catch (error) {
    console.error('Error fetching Protea schedules:', error);
    throw error;
  }
}

// Helper function to process port schedules with voyage validation
const processPortSchedules = (portData, vessels) => {
  const schedules = vessels.map(() => ({ 
    eta: { text: 'N/A', isActual: false }, 
    etd: { text: 'N/A', isActual: false } 
  }));

  if (!portData?.length) return schedules;

  portData.forEach(portCall => {
    portCall.facilityCalls.forEach(facilityCall => {
      const transport = facilityCall.transport;
      const vesselIndex = vessels.findIndex(v => {
        // Match both vessel name and voyage number
        const matchesVessel = v.name === transport.vessel.vesselName;
        const matchesVoyage = 
          transport.outboundService?.carrierVoyageNumber === v.voyage ||
          transport.inboundService?.carrierVoyageNumber === v.voyage;
        return matchesVessel && matchesVoyage;
      });
      
      if (vesselIndex !== -1) {  
        const arrival = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
        const departure = facilityCall.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');
        
        schedules[vesselIndex] = {
          eta: formatDate(arrival?.classifierDateTime, arrival?.eventClassifierCode),
          etd: formatDate(departure?.classifierDateTime, departure?.eventClassifierCode)
        };
      }
    });
  });

  return schedules;
};

// Helper function to process discharge schedule
const processDischargeSchedule = (dischargeData, portName, vesselData) => {
  if (!dischargeData?.vesselCalls) {
    return { eta: { text: 'N/A', isActual: false }, etd: { text: 'N/A', isActual: false } };
  }
  
  // Find the correct port call that matches both port and voyage number
  const call = dischargeData.vesselCalls.find(call => {
    // Check if this is the right port
    const isCorrectPort = call.facility.cityName === portName;

    // Then check if this matches our voyage number
    const matchesVoyage = 
      call.transport?.outboundService?.carrierVoyageNumber === vesselData.voyage ||
      call.transport?.inboundService?.carrierVoyageNumber === vesselData.voyage;

    return isCorrectPort && matchesVoyage;
  });

  if (!call) {
    return { eta: { text: 'N/A', isActual: false }, etd: { text: 'N/A', isActual: false } };
  }

  const arrival = call.callSchedules.find(s => s.transportEventTypeCode === 'ARRI');
  const departure = call.callSchedules.find(s => s.transportEventTypeCode === 'DEPA');

  // Check if this is the last discharge port
  const isLastDischargePort = DISCHARGE_PORTS[DISCHARGE_PORTS.length - 1].name === portName;
  
  // If this is the last discharge port and we have an actual arrival
  if (isLastDischargePort && arrival?.eventClassifierCode === 'ACT') {
    const arrivalDate = new Date(arrival.classifierDateTime);
    const oneDayAfter = new Date(arrivalDate);
    oneDayAfter.setDate(oneDayAfter.getDate() + 1);
    
    // If it's been less than a day since actual arrival, keep the vessel
    if (new Date() < oneDayAfter) {
      vesselData.keepVisible = true;
    }
  }

  return {
    eta: formatDate(arrival?.classifierDateTime, arrival?.eventClassifierCode),
    etd: formatDate(departure?.classifierDateTime, departure?.eventClassifierCode)
  };
};

// Date formatting helper
const formatDate = (dateTimeStr, eventClassifierCode) => {
  if (!dateTimeStr) return { text: 'N/A', isActual: false };
  const date = new Date(dateTimeStr);
  return {
    text: date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit'
    }).replace(',', ''),
    isActual: eventClassifierCode === 'ACT'
  };
};

export { fetchProteaSchedules };